import React from "react";


const RegistrationPageContent = () => {
    return (
        <div class="registration-info">
            <h3>Beauty</h3>
            <h1>CUDWARM FASHION WEEK</h1>
            <p>Registrations Now Open for Cudwarm Fashion Week 2024</p>
            <p><b>Registration Amount: Rs.5000</b></p>
        </div>
    )
};

export default RegistrationPageContent;