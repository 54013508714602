import React from 'react';

const SliderTwoContent = () => {
  return (
    <div className='overlay-text'>
        <h1>CUDWARM FASHION WEEK</h1>
        <p>Elegance Redefined</p>
        <hr/>
    </div>
  );
};

export default SliderTwoContent;